import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "./Pages/Home";
import Staking from "./Pages/staking";
import Dashboard from './Pages/dashboard';

function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Home />} />
					<Route path='/staking' element={<Staking />} />
					<Route path='/dashboard' element={<Dashboard />} />

				</Routes>
			</BrowserRouter>
		</div>




	)
}


export default App;
