import '../App.css';
import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import '../animateBg.css';
import logo from '../assets/logo.png';
import Animation from '../components/animation';
import Footer from '../components/footer';
import web from '../assets/web.png';
import dashboard from '../assets/dashboard.png';
import stake from '../assets/stake (2).png';
import wallet from '../assets/wallet.png';
import p2e from '../assets/p2e.png';
import soon from '../assets/soon.png';
import bannerRefferal from '../assets/banner-refferal2.png';
import walletPic from '../assets/walletImg.png';
import cover from '../assets/Cover.png';
import section1 from '../assets/1section.png';
import section2 from '../assets/2section.png';
import section3 from '../assets/3section.png';
import twitter from '../assets/TwitterF.png';
import tg from '../assets/TG.png';
import web2 from '../assets/WebF.png';
import insta from '../assets/instagram.png';
import Dashboard from './dashboard';
import bannerStaking from '../assets/BannerStaking.png';
import earn from '../assets/earnStake.png';
import walletStake from '../assets/Value stake.png';
import stakeCoin from '../assets/stakeCoin.png';
import share from '../assets/share.png';
import pools from '../assets/Pools Comming.png';

var $ = require("jquery");

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


const mint = () => {
	window.open("https://mint.nftoonworld.com");
}


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let myTokenIDs = [];
let x = '';
let myTokenURLs = '';
let myTokenIDsForm = [];
let z = 0;
let myStakeNFT = '';
let isApproved = false;
let getMyStakedTokens = [];
let getStakedDetailsWindow = 0;
let myStakedTokenCount;
let getRewards = [];
let q = -1;
let getRewardsRounded;
let elementArray = [];
let rewardsTime;
let rewardDetails;
let revealedStatus;
let approveToken;
let sweatshirtsPrice;
let tShirtsPrice;
let seatAtToonHallPrice;
let createACustomStickerPrice;
let writeYourStoryPrice;
let featureYourNFTInComicPrice;
let weiAmount;
let nftContractOwner;

// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses =

	[

	];

let whitelistAddresses2 = [];

const ABIStaking =

	[
		{
			"inputs": [
				{
					"internalType": "uint256[]",
					"name": "_tokenIDs",
					"type": "uint256[]"
				}
			],
			"name": "batchClaimRewards",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256[]",
					"name": "_tokenIDs",
					"type": "uint256[]"
				}
			],
			"name": "batchStakeNFT",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256[]",
					"name": "_tokenIDs",
					"type": "uint256[]"
				}
			],
			"name": "batchUnstakeNFT",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "itemCategoryNumber",
					"type": "uint256"
				},
				{
					"internalType": "string",
					"name": "email",
					"type": "string"
				},
				{
					"internalType": "uint256",
					"name": "quantity",
					"type": "uint256"
				}
			],
			"name": "buyItem",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_tokenID",
					"type": "uint256"
				}
			],
			"name": "claimRewards",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				},
				{
					"internalType": "bytes",
					"name": "",
					"type": "bytes"
				}
			],
			"name": "onERC721Received",
			"outputs": [
				{
					"internalType": "bytes4",
					"name": "",
					"type": "bytes4"
				}
			],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "orderNumber",
					"type": "uint256"
				}
			],
			"name": "recordSentOrders",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_rewardsAmount",
					"type": "uint256"
				}
			],
			"name": "setRewardsAmount",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"stateMutability": "nonpayable",
			"type": "constructor"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "previousOwner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "OwnershipTransferred",
			"type": "event"
		},
		{
			"inputs": [],
			"name": "renounceOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_createACustomSticker",
					"type": "uint256"
				}
			],
			"name": "setCreateACustomSticker",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_decimalNumber",
					"type": "uint256"
				}
			],
			"name": "setDecimalNumber",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_featureYourNFTInComic",
					"type": "uint256"
				}
			],
			"name": "setFeatureYourNFTInComic",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_nftContract",
					"type": "address"
				}
			],
			"name": "setNFTContract",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_rewardsCircle",
					"type": "uint256"
				}
			],
			"name": "setRewardsCircle",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_rewardsRate",
					"type": "uint256"
				}
			],
			"name": "setRewardsRate",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_seatAtToonHall",
					"type": "uint256"
				}
			],
			"name": "setSeatAtToonHall",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_sweatshirts",
					"type": "uint256"
				}
			],
			"name": "setSweatshirts",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_tokenContract",
					"type": "address"
				}
			],
			"name": "setTokenContract",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_tShirts",
					"type": "uint256"
				}
			],
			"name": "setTShirts",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_writeYourStory",
					"type": "uint256"
				}
			],
			"name": "setWriteYourStory",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_tokenID",
					"type": "uint256"
				}
			],
			"name": "stakeNFT",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "tokenWithdrawal",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "transferOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_tokenID",
					"type": "uint256"
				}
			],
			"name": "unstakeNFT",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"name": "countofMyStakedTokens",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "createACustomSticker",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "decimalNumber",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "featureYourNFTInComic",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "nft",
			"outputs": [
				{
					"internalType": "contract IERC721",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"name": "order",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "orderNumber",
					"type": "uint256"
				},
				{
					"internalType": "uint256",
					"name": "orderTime",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "owner",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "categoryNumber",
					"type": "uint256"
				},
				{
					"internalType": "string",
					"name": "emailAddress",
					"type": "string"
				},
				{
					"internalType": "uint256",
					"name": "quantity",
					"type": "uint256"
				},
				{
					"internalType": "uint256",
					"name": "paidAmount",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "orderCount",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "owner",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "rewardsAmount",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "rewardsCircle",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "rewardsRate",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "seatAtToonHall",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "sentOrderCount",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"name": "sentOrders",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "orderNumber",
					"type": "uint256"
				},
				{
					"internalType": "uint256",
					"name": "orderTime",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "owner",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "categoryNumber",
					"type": "uint256"
				},
				{
					"internalType": "string",
					"name": "emailAddress",
					"type": "string"
				},
				{
					"internalType": "uint256",
					"name": "quantity",
					"type": "uint256"
				},
				{
					"internalType": "uint256",
					"name": "paidAmount",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"name": "stakedItems",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"name": "stakedTokenOwner",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"name": "stakedTokens",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "sweatshirts",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "token",
			"outputs": [
				{
					"internalType": "contract IERC20",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"name": "tokenRewards",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"name": "tokenStakedDuration",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"name": "tokenStakedTime",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "tShirts",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "writeYourStory",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		}
	];

const addressStaking = "0x33E1270052150Ba8db3d1Fa27055730761612568";

const ABIToken = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burnFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const addressToken = "0xb91342EA719Cd1Ca6eA9E3341863aEf7956d0cA5";

const ABINFT = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const addressNFT = "0x8335f5bB8e0c79C17b0895446A67f24A6D45741D";

let contractStaking;
let contractToken;
let contractNFT;

class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_myTokenIDs: [],
		_obj: 'pic',
		_myTokenURLs: '',
		_num: '',
		_value: '',
		_myStakeNFT: '',
		_isApproved: false,
		_getMyStakedTokens: [],
		_getStakedDetailsWindow: 0,
		_myStakedTokenCount: '',
		_getRewards: [],
		_rewardDetails: [],
		_elementArray: [],
		_revealedStatus: false,
		_buyWindow1: 0,
		_buyWindow2: 0,
		_buyWindow3: 0,
		_buyWindow4: 0,
		_buyWindow5: 0,
		_buyWindow6: 0,
		_sendingAddress: '',
		_goodsQuantity: 0,
		_street: '',
		_country: '',
		_countryzipCode: '',
		_city: '',
		_approveToken: '',
		_sweatshirtsPrice: '',
		_tShirtsPrice: '',
		_seatAtToonHallPrice: '',
		_createACustomStickerPrice: '',
		_writeYourStoryPrice: '',
		_featureYourNFTInComicPrice: '',
		_approveWindow1: 0,
		_approveWindow2: 0,
		_approveWindow3: 0,
		_approveWindow4: 0,
		_approveWindow5: 0,
		_approveWindow6: 0,
		_weiAmount: '',
		_email: '',
		_nftContractOwner: '',
		owner: ''
	}

	async componentDidMount() {

		if (localStorage?.getItem('isWalletConnected') === 'true') {
			try {

				console.log("CONNECTED");



				const providerOptions = {
					walletconnect: {
						package: WalletConnectProvider, // required
						options: {
							infuraId: "bf933c3446b3464c988114813a1360ac" // required
						}
					}
				};

				const web3Modal = new Web3Modal({
					network: "mainnet", // optional
					cacheProvider: true, // optional
					providerOptions // required
				});

				const provider = await web3Modal.connect();

				//  Enable session (triggers QR Code modal)
				await provider.enable();

				const web3 = new Web3(provider);
				console.log("provider : " + provider);
				// Subscribe to accounts change
				provider.on("accountsChanged", (accounts) => {
					console.log(accounts);
				});

				// Subscribe to chainId change
				provider.on("chainChanged", (chainId) => {
					console.log(chainId);
				});

				// Subscribe to provider connection
				provider.on("connect", (info) => {
					console.log(info);
				});

				// Subscribe to provider disconnection
				provider.on("disconnect", (error) => {
					console.log(error);
				});

				// test if wallet is connected
				if (web3Modal.cachedProvider) {
					// connected now you can get accounts
					console.log("provider :" + web3Modal.cachedProvider);
					const accounts = await web3.eth.getAccounts();

					account = accounts[0];
					this.setState({ walletAddress: account });

					contractNFT = new web3.eth.Contract(ABINFT, addressNFT);
					console.log("contractNFT :" + contractNFT);

					contractToken = new web3.eth.Contract(ABIToken, addressToken);
					console.log("contractToken :" + contractToken);


					contractStaking = new web3.eth.Contract(ABIStaking, addressStaking);
					console.log("contractStaking :" + contractStaking);

					if (provider) {


						(async () => {


							if (web3Modal.cachedProvider != "walletconnect") {


								const chainId = 1;

								if (window.ethereum.networkVersion !== chainId) {
									try {
										await window.ethereum.request({
											method: 'wallet_switchEthereumChain',
											params: [{ chainId: web3.utils.toHex(chainId) }],
										});
									} catch (err) {
										// This error code indicates that the chain has not been added to MetaMask.
										if (err.code === 4902) {
											await window.ethereum.request({
												method: 'wallet_addEthereumChain',
												params: [
													{
														/*chainName: 'Smart Chain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

														/*	chainName: 'Goerli Test Network',
															chainId: web3.utils.toHex(chainId),
															nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
															rpcUrls: ['https://goerli.infura.io/v3/'],*/

														chainName: 'Ethereum Mainnet',
														chainId: web3.utils.toHex(chainId),
														nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
														rpcUrls: ['https://mainnet.infura.io/v3/'],
													},
												],
											});
										}
									}
								}





								try {

									nftContractOwner = await contractNFT.methods.owner().call();
									this.setState({ _nftContractOwner: nftContractOwner });
									console.log("Owner" + owner);

									totalSupplyNFT = await contractNFT.methods.totalSupply().call();
									this.setState({ totalSupply: totalSupplyNFT });
									console.log("Total Supply:" + totalSupplyNFT);

									isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
									this.setState({ _isApproved: isApproved });
									console.log(isApproved);

									publicSale = await contractNFT.methods.balanceOf(account).call();
									this.setState({ myNFTWallet: publicSale });

									revealedStatus = await contractNFT.methods.revealed().call();
									this.setState({ _revealedStatus: revealedStatus });

									sweatshirtsPrice = await contractStaking.methods.sweatshirts().call();
									this.setState({ _sweatshirtsPrice: sweatshirtsPrice });

									tShirtsPrice = await contractStaking.methods.tShirts().call();
									this.setState({ _tShirtsPrice: tShirtsPrice });

									seatAtToonHallPrice = await contractStaking.methods.seatAtToonHall().call();
									this.setState({ _seatAtToonHallPrice: seatAtToonHallPrice });

									createACustomStickerPrice = await contractStaking.methods.createACustomSticker().call();
									this.setState({ _createACustomStickerPrice: createACustomStickerPrice });

									writeYourStoryPrice = await contractStaking.methods.writeYourStory().call();
									this.setState({ _writeYourStoryPrice: writeYourStoryPrice });

									featureYourNFTInComicPrice = await contractStaking.methods.featureYourNFTInComic().call();
									this.setState({ _featureYourNFTInComicPrice: featureYourNFTInComicPrice });



									for (let y = 0; y < publicSale; y++) {
										myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
										this.setState({ _myTokenIDs: myTokenIDs })

										/*		rewardsTime[myTokenIDs[y]] = await contractStaking.methods.tokenStakedTime(account, myTokenIDs[y]).call();
				
												let timeDuration = Date.now() - (myTokenIDs[y] * 1000);
												getRewards[y] = timeDuration * 1 / 86400000;
				
												rewardDetails[y] = [myTokenIDs[y], getRewards[y].toFixed(4)];
												this.setState({ _rewardDetails: rewardDetails });*/

									}

									console.log("myTokenIDs : " + myTokenIDs);
									console.log("myTokenIDs : " + this.state._myTokenIDs);




								} catch (err) {
									console.log("err: " + err);

								}
							} else {

								const chainId = 1;

								if (WalletConnectProvider.networkVersion !== chainId) {
									try {
										await WalletConnectProvider.request({
											method: 'wallet_switchEthereumChain',
											params: [{ chainId: web3.utils.toHex(chainId) }],
										});
									} catch (err) {
										// This error code indicates that the chain has not been added to MetaMask.
										if (err.code === 4902) {
											await window.ethereum.request({
												method: 'wallet_addEthereumChain',
												params: [
													{
														/*chainName: 'Smart Chain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

														/*chainName: 'Goerli Test Network',
														chainId: web3.utils.toHex(chainId),
														nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
														rpcUrls: ['https://goerli.infura.io/v3/'],*/

														chainName: 'Ethereum Mainnet',
														chainId: web3.utils.toHex(chainId),
														nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
														rpcUrls: ['https://mainnet.infura.io/v3/'],
													},
												],
											});
										}
									}
								}




								try {

									try {
										localStorage.setItem('isWalletConnected', true);
									} catch (ex) {
										console.log(ex)
									}

									nftContractOwner = await contractNFT.methods.owner().call();
									this.setState({ _nftContractOwner: nftContractOwner });

									console.log("Owner" + owner);

									totalSupplyNFT = await contractNFT.methods.totalSupply().call();
									this.setState({ totalSupply: totalSupplyNFT });

									console.log("Total Supply:" + totalSupplyNFT);

									isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
									this.setState({ _isApproved: isApproved });
									console.log(isApproved);

									publicSale = await contractNFT.methods.balanceOf(account).call();
									this.setState({ myNFTWallet: publicSale });

									revealedStatus = await contractNFT.methods.revealed().call();
									this.setState({ _revealedStatus: revealedStatus });

									sweatshirtsPrice = await contractStaking.methods.sweatshirts().call();
									this.setState({ _sweatshirtsPrice: sweatshirtsPrice });

									tShirtsPrice = await contractStaking.methods.tShirts().call();
									this.setState({ _tShirtsPrice: tShirtsPrice });

									seatAtToonHallPrice = await contractStaking.methods.seatAtToonHall().call();
									this.setState({ _seatAtToonHallPrice: seatAtToonHallPrice });

									createACustomStickerPrice = await contractStaking.methods.createACustomSticker().call();
									this.setState({ _createACustomStickerPrice: createACustomStickerPrice });

									writeYourStoryPrice = await contractStaking.methods.writeYourStory().call();
									this.setState({ _writeYourStoryPrice: writeYourStoryPrice });

									featureYourNFTInComicPrice = await contractStaking.methods.featureYourNFTInComic().call();
									this.setState({ _featureYourNFTInComicPrice: featureYourNFTInComicPrice });



									for (let y = 0; y < publicSale; y++) {
										myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
										this.setState({ _myTokenIDs: myTokenIDs });



										/*	rewardsTime[myTokenIDs[y]] = await contractStaking.methods.tokenStakedTime(account, myTokenIDs[y]).call();
			
											let timeDuration = Date.now() - (myTokenIDs[y] * 1000);
											getRewards[y] = timeDuration * 1 / 86400000;
			
											rewardDetails[y] = [myTokenIDs[y], getRewards[y].toFixed(4)];
											this.setState({ _rewardDetails: rewardDetails });*/

									}




									console.log("myTokenIDs : " + myTokenIDs);
									console.log("myTokenIDs : " + this.state._myTokenIDs);




								} catch (err) {
									console.log("err: " + err);

								}
							}
						})();

						//.....................................................................//

						// Legacy providers may only have ethereum.sendAsync
						const chainId = await provider.request({
							method: 'eth_chainId'
						})

					} else {

						// if the provider is not detected, detectEthereumProvider resolves to null
						console.error('Please install a Valid Wallet');
						alert('A valid provider could not be found!');

					}
				}



				//await this.walletConnect();
			} catch (ex) {
				console.log(ex);
			}
		} else {

			console.log("NOT CONNECTED");

		}

	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contractNFT.methods.owner().call();


			//	if (account != owner) {


			try {


				console.log("minAmount:" + mintAmount);
				console.log("valueOfNFTs:" + valueOfNFTs);

				//contract = new window.web3.eth.Contract(ABI,'0x802ec13e2e3afe078bc15035f80f0f82bea9bc6c');

				this.setState({ statusError: false, statusLoading: true });
				await contractNFT.methods.mint(mintAmount * 1).send({ /*gasLimit: 285000, maxPriorityFeePerGas: "196000000000", maxFeePerGas: "202000000000", */from: account, value: this.state.totalValue * 0 });
				this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + this.state.mintAmount);

			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);


			}
			/*} else {
	
					try {
	
	
						console.log("minAmount:" + mintAmount);
						console.log("valueOfNFTs:" + valueOfNFTs);
	
						//contract = new window.web3.eth.Contract(ABI,'0x802ec13e2e3afe078bc15035f80f0f82bea9bc6c');
	
						this.setState({ statusError: false, statusLoading: true });
						await contract.methods.mint(mintAmount * 1).send({ from: account, value: this.state.totalValue * 1 * 0 });
						this.setState({ statusLoading: false, success: true });
						console.log("Mint Amount :" + this.state.mintAmount);
	
					} catch (err) {
						this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
						console.log(err);
	
	
					}
	
	
	
	
	
	
				}*/
		} catch (err) {

			console.log(err);

		}
	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contractNFT = new web3.eth.Contract(ABINFT, addressNFT);
			console.log("contractNFT :" + contractNFT);

			contractToken = new web3.eth.Contract(ABIToken, addressToken);
			console.log("contractToken :" + contractToken);


			contractStaking = new web3.eth.Contract(ABIStaking, addressStaking);
			console.log("contractStaking :" + contractStaking);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						try {
							localStorage.setItem('isWalletConnected', true);
						} catch (ex) {
							console.log(ex)
						}

						const chainId = 1;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart Chain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],
											},
										],
									});
								}
							}
						}





						try {

							nftContractOwner = await contractNFT.methods.owner().call();
							this.setState({ _nftContractOwner: nftContractOwner });

							console.log("Owner" + owner);

							totalSupplyNFT = await contractNFT.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
							this.setState({ _isApproved: isApproved });
							console.log(isApproved);

							publicSale = await contractNFT.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							revealedStatus = await contractNFT.methods.revealed().call();
							this.setState({ _revealedStatus: revealedStatus });

							sweatshirtsPrice = await contractStaking.methods.sweatshirts().call();
							this.setState({ _sweatshirtsPrice: sweatshirtsPrice });

							tShirtsPrice = await contractStaking.methods.tShirts().call();
							this.setState({ _tShirtsPrice: tShirtsPrice });

							seatAtToonHallPrice = await contractStaking.methods.seatAtToonHall().call();
							this.setState({ _seatAtToonHallPrice: seatAtToonHallPrice });

							createACustomStickerPrice = await contractStaking.methods.createACustomSticker().call();
							this.setState({ _createACustomStickerPrice: createACustomStickerPrice });

							writeYourStoryPrice = await contractStaking.methods.writeYourStory().call();
							this.setState({ _writeYourStoryPrice: writeYourStoryPrice });

							featureYourNFTInComicPrice = await contractStaking.methods.featureYourNFTInComic().call();
							this.setState({ _featureYourNFTInComicPrice: featureYourNFTInComicPrice });


							for (let y = 0; y < publicSale; y++) {
								myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _myTokenIDs: myTokenIDs });

								/*		rewardsTime[myTokenIDs[y]] = await contractStaking.methods.tokenStakedTime(account, myTokenIDs[y]).call();
		
										let timeDuration = Date.now() - (myTokenIDs[y] * 1000);
										getRewards[y] = timeDuration * 1 / 86400000;
		
										rewardDetails[y] = [myTokenIDs[y], getRewards[y].toFixed(4)];
										this.setState({ _rewardDetails: rewardDetails });*/

							}

							console.log("myTokenIDs : " + myTokenIDs);
							console.log("myTokenIDs : " + this.state._myTokenIDs);




						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 1;


						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart Chain',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],
											},
										],
									});
								}
							}
						}




						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}


							nftContractOwner = await contractNFT.methods.owner().call();
							this.setState({ _nftContractOwner: nftContractOwner });

							console.log("Owner" + owner);

							totalSupplyNFT = await contractNFT.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });

							console.log("Total Supply:" + totalSupplyNFT);


							isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
							this.setState({ _isApproved: isApproved });
							console.log(isApproved);

							publicSale = await contractNFT.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							revealedStatus = await contractNFT.methods.revealed().call();
							this.setState({ _revealedStatus: revealedStatus });

							sweatshirtsPrice = await contractStaking.methods.sweatshirts().call();
							this.setState({ _sweatshirtsPrice: sweatshirtsPrice });

							tShirtsPrice = await contractStaking.methods.tShirts().call();
							this.setState({ _tShirtsPrice: tShirtsPrice });

							seatAtToonHallPrice = await contractStaking.methods.seatAtToonHall().call();
							this.setState({ _seatAtToonHallPrice: seatAtToonHallPrice });

							createACustomStickerPrice = await contractStaking.methods.createACustomSticker().call();
							this.setState({ _createACustomStickerPrice: createACustomStickerPrice });

							writeYourStoryPrice = await contractStaking.methods.writeYourStory().call();
							this.setState({ _writeYourStoryPrice: writeYourStoryPrice });

							featureYourNFTInComicPrice = await contractStaking.methods.featureYourNFTInComic().call();
							this.setState({ _featureYourNFTInComicPrice: featureYourNFTInComicPrice });

							for (let y = 0; y < publicSale; y++) {
								myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _myTokenIDs: myTokenIDs })

								/*	rewardsTime[myTokenIDs[y]] = await contractStaking.methods.tokenStakedTime(account, myTokenIDs[y]).call();
	
									let timeDuration = Date.now() - (myTokenIDs[y] * 1000);
									getRewards[y] = timeDuration * 1 / 86400000;
	
									rewardDetails[y] = [myTokenIDs[y], getRewards[y].toFixed(4)];
									this.setState({ _rewardDetails: rewardDetails });*/

							}




							console.log("myTokenIDs : " + myTokenIDs);
							console.log("myTokenIDs : " + this.state._myTokenIDs);



						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}
		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload(true);

		try {
			localStorage.setItem('isWalletConnected', false)
		} catch (ex) {
			console.log(ex)
		}

	}

	async stake(staking_id) {


		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + staking_id);

		try {
			isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
			this.setState({ _isApproved: isApproved });
			console.log(isApproved);

			if (isApproved) {
				await contractStaking.methods.stakeNFT(staking_id).send({ from: account });

			} else {

				await contractNFT.methods.setApprovalForAll(addressStaking, true).send({ from: account });

			}

		} catch (err) {
			console.log(err);
		}


	}

	async unstake(unstaking_id) {

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + unstaking_id);

		try {

			await contractStaking.methods.unstakeNFT(unstaking_id).send({ /*gasLimit: 285000,*/ from: account });


		} catch (err) {
			console.log(err);
		}
	}

	getStakedDetails = async event => {
		event.preventDefault();

		if (this.state.walletAddress != "") {
			getStakedDetailsWindow = 1;
			this.setState({ _getStakedDetailsWindow: getStakedDetailsWindow });
			console.log("getStakedDetailsWindow :" + getStakedDetailsWindow);
		}

		try {

			/*	for (let y = 0; y < publicSale; y++) {
					myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
					this.setState({ _myTokenIDs: myTokenIDs })
	
					rewardsTime[myTokenIDs[y]] = await contractStaking.methods.tokenStakedTime(account, myTokenIDs[y]).call();
	
					let timeDuration = Date.now() - (rewardsTime[myTokenIDs[y]] * 1000);
					getRewards[y] = timeDuration * 1 / 86400000;
	
					rewardDetails[y] = [myTokenIDs[y], getRewards[y].toFixed(4)];
					this.setState({ _rewardDetails: rewardDetails });				
	*/
			myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
			console.log("myStakedTokenCount : " + myStakedTokenCount);

			let a;
			for (a = 0; a < myStakedTokenCount; a++) {
				getMyStakedTokens[a] = await contractStaking.methods.stakedTokens(account, a).call();
				//	getRewards = await contractStaking.methods.getRewards(getMyStakedTokens[a]).send({ from: account });

				let tokenTime = await contractStaking.methods.tokenStakedTime(account, getMyStakedTokens[a]).call();
				let timeDuration = Date.now() - (tokenTime * 1000);

				if (timeDuration >= 0) {

					getRewards[a] = (Date.now() - (tokenTime * 1000)) * 10 / 86400000;

				} else {
					getRewards[a] = 0;
				}

				elementArray[a] = [getMyStakedTokens[a], getRewards[a].toFixed(4)];
				this.setState({ _elementArray: elementArray });



				this.setState({ _getRewards: getRewards });
				console.log("tokenTime :" + tokenTime * 1000);
				console.log("tokenTimeDuration :" + timeDuration);
				console.log("getRewards : " + getRewards[a].toFixed(2));

				this.setState({ _getMyStakedTokens: getMyStakedTokens });
				console.log("getMyStakedTokens : " + getMyStakedTokens);
				console.log("elementArray[a] : " + elementArray[a]);
				console.log("elementArray[a][0] : " + elementArray[a][0]);


			}





		} catch (err) {
			console.log(err);
		}

	}

	closeWindow = async event => {
		event.preventDefault();

		getStakedDetailsWindow = 0;
		this.setState({ _getStakedDetailsWindow: getStakedDetailsWindow });

	}

	async claimRewards(claiming_id) {

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + claiming_id);

		try {

			await contractStaking.methods.claimRewards(claiming_id).send({ /*gasLimit: 285000,*/ from: account });

		} catch (err) {
			console.log(err);
		}
	}

	approveWindow1 = async event => {
		event.preventDefault();

		this.setState({ _approveWindow1: 1 });

	}

	approveWindow2 = async event => {
		event.preventDefault();

		this.setState({ _approveWindow2: 1 });

	}

	approveWindow3 = async event => {
		event.preventDefault();

		this.setState({ _approveWindow3: 1 });

	}

	approveWindow4 = async event => {
		event.preventDefault();

		this.setState({ _approveWindow4: 1 });

	}

	approveWindow5 = async event => {
		event.preventDefault();

		this.setState({ _approveWindow5: 1 });

	}

	approveWindow6 = async event => {
		event.preventDefault();

		this.setState({ _approveWindow6: 1 });

	}

	buyWindow1 = async event => {
		event.preventDefault();

		this.setState({ _buyWindow1: 1 });

	}

	buyWindow2 = async event => {
		event.preventDefault();

		this.setState({ _buyWindow2: 1 });

	}

	buyWindow3 = async event => {
		event.preventDefault();

		this.setState({ _buyWindow3: 1 });

	}

	buyWindow4 = async event => {
		event.preventDefault();

		this.setState({ _buyWindow4: 1 });

	}

	buyWindow5 = async event => {
		event.preventDefault();

		this.setState({ _buyWindow5: 1 });

	}

	buyWindow6 = async event => {
		event.preventDefault();

		this.setState({ _buyWindow6: 1 });

	}

	closeWindow2 = async event => {
		event.preventDefault();

		this.setState({ _buyWindow1: 0 });
		this.setState({ _buyWindow2: 0 });
		this.setState({ _buyWindow3: 0 });
		this.setState({ _buyWindow4: 0 });
		this.setState({ _buyWindow5: 0 });
		this.setState({ _buyWindow6: 0 });

	}

	closeWindow3 = async event => {
		event.preventDefault();

		this.setState({ _approveWindow1: 0 });
		this.setState({ _approveWindow2: 0 });
		this.setState({ _approveWindow3: 0 });
		this.setState({ _approveWindow4: 0 });
		this.setState({ _approveWindow5: 0 });
		this.setState({ _approveWindow6: 0 });

	}

	buySweatshirts = async event => {
		event.preventDefault();

		try {

			await contractStaking.methods.buyItem(1 * 1, this.state._email, this.state._goodsQuantity).send({ gasLimit: 285000, from: account, value: 0 });

		} catch (err) {
			console.log(err);
		}

	}

	tShirts = async event => {
		event.preventDefault();

		try {

			await contractStaking.methods.buyItem(2 * 1, this.state._email, this.state._goodsQuantity).send({ gasLimit: 285000, from: account, value: 0 });

		} catch (err) {
			console.log(err);
		}

	}

	seatAtToonHall = async event => {
		event.preventDefault();

		try {

			await contractStaking.methods.buyItem(3 * 1, this.state._email, this.state._goodsQuantity).send({ gasLimit: 285000, from: account, value: 0 });

		} catch (err) {
			console.log(err);
		}

	}

	createACustomSticker = async event => {
		event.preventDefault();

		try {

			await contractStaking.methods.buyItem(4 * 1, this.state._email, this.state._goodsQuantity).send({ gasLimit: 285000, from: account, value: 0 });

		} catch (err) {
			console.log(err);
		}

	}

	writeYourStory = async event => {
		event.preventDefault();

		try {

			await contractStaking.methods.buyItem(5 * 1, this.state._email, this.state._goodsQuantity).send({ gasLimit: 285000, from: account, value: 0 });

		} catch (err) {
			console.log(err);
		}

	}

	featureYourNFTInComic = async event => {
		event.preventDefault();

		try {

			await contractStaking.methods.buyItem(6 * 1, this.state._email, this.state._goodsQuantity).send({ gasLimit: 285000, from: account, value: 0 });

		} catch (err) {
			console.log(err);
		}

	}

	approveForSweatShirts = async event => {
		event.preventDefault();

		let amount = this.state._goodsQuantity * this.state._sweatshirtsPrice;


		try {


			let allowance = await contractToken.methods.allowance(account, addressStaking).call();
			weiAmount = amount * 1000000000;

			//weiAmount = new BigNumber(amount).shiftedBy(18).toString();
			console.log("weiAmount: " + weiAmount);

			if (allowance > 0) {

				await contractToken.methods.increaseAllowance(addressStaking, weiAmount.toString()).send({ gasLimit: 685000, maxPriorityFeePerGas: "50000000000", maxFeePerGas: "196000000000", from: account });

			} else {

				await contractToken.methods.approve(addressStaking, weiAmount.toString()).send({ gasLimit: 685000, maxPriorityFeePerGas: "50000000000", maxFeePerGas: "196000000000", from: account });

			}

			//this.setState({ _approved: true });



		} catch (err) {
			this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
			console.log(err);

		}

		//let amount = Web3.utils.fromWei(this.state._goodsQuantity*1 * this.state._sweatshirtsPrice*1,"ether");



		console.log("amount:" + amount);
		console.log("goodsQuantity:" + this.state._goodsQuantity);
		console.log("sweatshirtsPrice:" + this.state._sweatshirtsPrice);


	}

	approveFortShirts = async event => {
		event.preventDefault();

		let amount = this.state._goodsQuantity * this.state._tShirtsPrice;


		try {

			let allowance = await contractToken.methods.allowance(account, addressStaking).call();
			weiAmount = amount * 1000000000;

			//weiAmount = new BigNumber(amount).shiftedBy(18).toString();
			console.log("weiAmount: " + weiAmount);

			if (allowance > 0) {

				await contractToken.methods.increaseAllowance(addressStaking, weiAmount.toString()).send({ gasLimit: 685000, maxPriorityFeePerGas: "50000000000", maxFeePerGas: "196000000000", from: account });

			} else {

				await contractToken.methods.approve(addressStaking, weiAmount.toString()).send({ gasLimit: 685000, maxPriorityFeePerGas: "50000000000", maxFeePerGas: "196000000000", from: account });

			}

			//this.setState({ _approved: true });



		} catch (err) {
			console.log(err);
		}
	}

	approveForseatAtToonHall = async event => {
		event.preventDefault();
		let amount = this.state._goodsQuantity * this.state._seatAtToonHallPrice;

		try {


			let allowance = await contractToken.methods.allowance(account, addressStaking).call();
			weiAmount = amount * 1000000000;

			//weiAmount = new BigNumber(amount).shiftedBy(18).toString();
			console.log("weiAmount: " + weiAmount);

			if (allowance > 0) {

				await contractToken.methods.increaseAllowance(addressStaking, weiAmount.toString()).send({ gasLimit: 685000, maxPriorityFeePerGas: "50000000000", maxFeePerGas: "196000000000", from: account });

			} else {

				await contractToken.methods.approve(addressStaking, weiAmount.toString()).send({ gasLimit: 685000, maxPriorityFeePerGas: "50000000000", maxFeePerGas: "196000000000", from: account });

			}

			//this.setState({ _approved: true });



		} catch (err) {
			console.log(err);
		}
	}

	approveForcreateACustomSticker = async event => {
		event.preventDefault();
		let amount = this.state._goodsQuantity * this.state._createACustomStickerPrice;

		try {


			let allowance = await contractToken.methods.allowance(account, addressStaking).call();
			weiAmount = amount * 1000000000;

			//weiAmount = new BigNumber(amount).shiftedBy(18).toString();
			console.log("weiAmount: " + weiAmount);

			if (allowance > 0) {

				await contractToken.methods.increaseAllowance(addressStaking, weiAmount.toString()).send({ gasLimit: 685000, maxPriorityFeePerGas: "50000000000", maxFeePerGas: "196000000000", from: account });

			} else {

				await contractToken.methods.approve(addressStaking, weiAmount.toString()).send({ gasLimit: 685000, maxPriorityFeePerGas: "50000000000", maxFeePerGas: "196000000000", from: account });

			}

			//this.setState({ _approved: true });



		} catch (err) {
			console.log(err);
		}
	}

	approveForwriteYourStory = async event => {
		event.preventDefault();
		let amount = this.state._goodsQuantity * this.state._writeYourStoryPrice;

		try {


			let allowance = await contractToken.methods.allowance(account, addressStaking).call();
			weiAmount = amount * 1000000000;

			//weiAmount = new BigNumber(amount).shiftedBy(18).toString();
			console.log("weiAmount: " + weiAmount);

			if (allowance > 0) {

				await contractToken.methods.increaseAllowance(addressStaking, weiAmount.toString()).send({ gasLimit: 685000, maxPriorityFeePerGas: "50000000000", maxFeePerGas: "196000000000", from: account });

			} else {

				await contractToken.methods.approve(addressStaking, weiAmount.toString()).send({ gasLimit: 685000, maxPriorityFeePerGas: "50000000000", maxFeePerGas: "196000000000", from: account });

			}

			//this.setState({ _approved: true });



		} catch (err) {
			console.log(err);
		}
	}

	approveForfeatureYourNFTInComic = async event => {
		event.preventDefault();
		let amount = this.state._goodsQuantity * this.state._featureYourNFTInComicPrice;

		try {


			let allowance = await contractToken.methods.allowance(account, addressStaking).call();
			weiAmount = amount * 1000000000;

			//weiAmount = new BigNumber(amount).shiftedBy(18).toString();
			console.log("weiAmount: " + weiAmount);

			if (allowance > 0) {

				await contractToken.methods.increaseAllowance(addressStaking, weiAmount.toString()).send({ gasLimit: 685000, maxPriorityFeePerGas: "50000000000", maxFeePerGas: "196000000000", from: account });

			} else {

				await contractToken.methods.approve(addressStaking, weiAmount.toString()).send({ gasLimit: 685000, maxPriorityFeePerGas: "50000000000", maxFeePerGas: "196000000000", from: account });

			}

			//this.setState({ _approved: true });



		} catch (err) {
			console.log(err);
		}
	}

	render() {

		return (

			<div class="allWrap">

				<div class="light">
					<div class="headers2-2">

						<div class="logo"><img class="logoPic" onClick={() => window.location.href = '/'} src={logo} /></div>
						<div class="socialIcons">

							<img src={twitter} />
							<img src={tg} />
							<img src={web2} />
							<img src={insta} />

						</div>
						<div class="tabs">
							<div class="mobileNavbar2">
								<img class="headerImg" onClick={() => window.location.href = '/dashboard'} src={dashboard} />
							</div>

							<div class="mobileNavbar2">
								<img class="headerImg" onClick={() => window.location.href = '/staking'} src={stake} />
							</div>

							<div class="mobileNavbar2">
								<img class="headerImg" src={wallet} />
							</div>

							<div class="mobileNavbar2">
								<img class="headerImg2" src={p2e} />
							</div>
						</div>

						<div class="priceDetails">

							<div class="imageAndCon">
								<div>Estimated Value</div>
								<img src={web} />
							</div>

							<div class="price">
								$100.000
							</div>

							<div class="price2">
								= 4.000.000.000 $RGB
							</div>

						</div>
					</div>
					<div class="section2">

						<div class="headers">

							<div class="logo"><img class="logoPic" onClick={() => window.location.href = '/'} src={logo} /></div>

							<div class="priceDetails">

								<div class="imageAndCon">
									<div>Estimated Value</div>
									<img src={web} />
								</div>

								<div class="price">
									$100.000
								</div>

								<div class="price2">
									= 4.000.000.000 $RGB
								</div>

							</div>

							<div class="imageAndCon2">
								<img class="headerImg" onClick={() => window.location.href = '/dashboard'} src={dashboard} />
								<div class="btnNavbar" onClick={() => window.location.href = '/dashboard'}>Dashboard</div>
							</div>

							<div class="imageAndCon2">
								<img onClick={() => window.location.href = '/staking'} src={stake} />
								<div class="onClick" onClick={() => window.location.href = '/staking'}>Stake</div>
							</div>

							<div class="imageAndCon2">
								<img src={wallet} />
								<div class="btnNavbar">Wallet</div>
							</div>

							<div class="imageAndCon2">
								<img src={p2e} />
								<div class="btnNavbar">Play to Earn <img class="soon" src={soon} /></div>

							</div>

							<div class="socialIcons">

								<img src={twitter} />
								<img src={tg} />
								<img src={web2} />
								<img src={insta} />

							</div>

						</div>

						<div class="introduction" >

							<div class="headers2">
								<div>
									<button class="walletDashboard">Connect Wallet</button>
								</div>
							</div>

							<div class="bannerStaking">
								<img src={bannerStaking} />
							</div>

							<div class="bannerRef2-2">

								<div class="bannerStake">
									<div class="bannerStakeMain2Up">
										<div class="bannerStake1">Stake RGB</div>
										<div class="bannerStake2">Earn BNB</div>
									</div>
									<div class="bannerStakeBorder"></div>
									<div class="bannerStakeMain2">
										<div class="bannerStake3">Balance : 0.2 RGB</div>

										<div class="maxCon">
											<input type="text" class="maxCon2" name="yourName" placeholder="0.00" />
											<div><button class="maxBtn">Max</button></div>

										</div>
										<div class="dayCon">
											<button class="dayConDiv1"><span class="days1">30 days</span></button>
											<button class="dayConDiv"><span class="days">90 days</span></button>
											<button class="dayConDiv"><span class="days">180 days</span></button>
											<button class="dayConDiv"><span class="days">360 days</span></button>

										</div>

										<div class="bannerStakeSection3">
											<div class="stakeDetails">
												<div class="stakeFont">RGB to be locked</div>
												<div class="stakeFont">44.000.000 RGB</div>
											</div>

											<div class="stakeDetails">
												<div class="stakeFont">APR</div>
												<div class="stakeFont">80%</div>
											</div>

											<div class="stakeDetails">
												<div class="stakeFont">Duration</div>
												<div class="stakeFont">30 days</div>
											</div>

											<div class="stakeDetails">
												<div class="stakeFont">Unlock on</div>
												<div class="stakeFont">21 Oct 2022</div>
											</div>

											<div class="stakeDetails">
												<div class="stakeFont">ROI</div>
												<div class="stakeFont">$1000</div>
											</div>
										</div>

										<button class="approveStake">Approve</button>
									</div>
								</div>

								<div class="bannerStake">
									<div class="bannerStakeMain2-2">
										<div class="stakePicAndCon">
											<img src={earn} />
											<div>
												<div class="bannerStake1">Earn RGB</div>
												<div class="bannerStake2">Stake BNB</div>
											</div>
										</div>

										<div>
											<div class="apr">APR 50%</div>
										</div>
									</div>
									<div class="bannerStakeBorder"></div>
									<div class="bannerStakeMain2">

										<div class="stake2Box">
											<div class="stake2Box1Con">
												<img src={walletStake} />
												<div class="stake2Box1ConFont">Total Value Staked</div>
											</div>

											<div>
												<div class="stake2Box1ConFont">75,555.000 RGB</div>
											</div>
										</div>

										<div class="stake2Box2">
											<div class="stake2Box1Con">
												<img src={stakeCoin} />
												<div class="stake2Box1ConFont">Total Value Staked</div>
											</div>

											<div class="shareCon">
												<div class="stake2Box1ConFont2">$150</div>
												<div><img src={share} /></div>
											</div>
										</div>

										<div class="withdrawSection">
											<div class="stakedRGB">Staked : 2.565.000 RGB</div>
											<div class="selecAndWithdraw">

												<div class="maxSelect">
													<input type="text" class="maxCon3" name="yourName" placeholder="0.00" />
													<div><button class="maxBtn">Max</button></div>

												</div>

												<div class="withdrawBtnDiv">
													<button class="withdrawBtn">Withdraw</button>
												</div>
											</div>
										</div>

										<div class="approveSection">
											<div class="stakedRGB2">Stake RGB</div>
											<button class="approveStake2">Enable</button>
										</div>
									</div>
								</div>

								<div class="bannerStake">
									<div class="bannerStakeMain2-2">
										<div class="stakePicAndCon">
											<img src={earn} />
											<div>
												<div class="bannerStake1">Earn RGB</div>
												<div class="bannerStake2">Stake BNB</div>
											</div>
										</div>

										<div>
											<div class="apr">APR 50%</div>
										</div>
									</div>
									<div class="bannerStakeBorder"></div>
									<div class="bannerStakeMain2">

										<div class="stake2Box">
											<div class="stake2Box1Con">
												<img src={walletStake} />
												<div class="stake2Box1ConFont">Total Value Staked</div>
											</div>

											<div>
												<div class="stake2Box1ConFont">75,555.000 RGB</div>
											</div>
										</div>

										<div class="stake2Box2">
											<div class="stake2Box1Con">
												<img src={stakeCoin} />
												<div class="stake2Box1ConFont">Total Value Staked</div>
											</div>

											<div class="shareCon">
												<div class="stake2Box1ConFont2">$150</div>
												<div><img src={share} /></div>
											</div>
										</div>

										<div class="withdrawSection">
											<div class="stakedRGB">Staked : 2.565.000 RGB</div>
											<div class="selecAndWithdraw">

												<div class="maxSelect">
													<input type="text" class="maxCon3" name="yourName" placeholder="0.00" />
													<div><button class="maxBtn">Max</button></div>

												</div>

												<div>
													<button class="withdrawBtn">Withdraw</button>
												</div>
											</div>
										</div>

										<div class="approveSection">
											<div class="stakedRGB2">Stake RGB</div>
											<button class="approveStake2">Enable</button>
										</div>
									</div>
								</div>
							</div>

							<div class="bannerStakingPools">
								<img src={pools} />
							</div>

						</div>

					</div>
					<div class="footer">
						<div><img src={logo} /></div>
						<div class="copyright">© Copyright 2022, All Rights Reserved by RBG Earn</div>
					</div>
				</div>

			</div >)
	}
}

export default App;
