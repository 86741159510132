import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';
import tweet from '../assets/twitter.png';
import opensea from '../assets/opensea2.png';

const twitter = () => {
	window.open("https://twitter.com/NFToonWorld");
}

const os = () => {
	window.open("https://opensea.io/collection/boredbunny-toons");
}

class Footer extends Component {


	render() {

		return (

			<div class="footer">

				<div class="footer2">
					<img onClick={twitter} src={tweet}/>
					<img onClick={os} src={opensea}/>
				</div>
				<div class="copyright">Copyright © 2022, NFToonWorld</div>

			</div>)
	}
}

export default Footer;